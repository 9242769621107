/* square buttons */
.rec.rec-arrow {
  border-radius: 0;
  background-color: white;
  border-radius: 50%;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
}

/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  /* box-shadow: inset 0 0 1px 1px lightgrey; */
  background-color: white;
}
/* 
.searchtext {
  width: 130px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url("https://www.w3schools.com/howto/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
.searchtext:focus {
  width: 100%;
} */
/* input[type="text"] {
  width: 130px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url("searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

input[type="text"]:focus {
  width: 100%;
} */
.MuiInputBase-input {
  width: 600px;
  outline-color: lawngreen;
}

input[type="text"],
[type="password"] {
  width: 300px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  /* padding: 12px 20px 12px 40px; */
  padding: 12px 0px 12px 10px;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.file-upload {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.file-upload__label {
  width: 300px;
  display: block;
  padding: 1em 2em;
  color: #fff;
  background: #222;
  border-radius: 0.4em;
  cursor: pointer;
  transition: ease-in-out 3s;
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}
/* button[type="submit"] {
  background-color: lightcoral;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  border: none;
  margin-top: 20px;
} */

.errorMsg {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}

input[type="text"],
[type="password"]:focus {
  /* width: 100%; */
  outline-color: tomato;
}
.StarRating-module_stars__3roiw:focus {
  outline-color: #ffffff;
}

label {
  margin: 0.5em 0;
  color: #101522;
  display: block;
}

.error {
  margin: 0.5em 0;
  color: #da0320;
  display: block;
}

* {
  margin: 0;
}

.btnside {
  padding: 14px;
  font-size: 16px;
  color: #101522;
  transition: ease-in-out 0.1s;
  border-radius: 50px;
  text-align: left;
  /* background-color: wheat; */
  cursor: pointer;
  display: inline;
  margin-bottom: 10px;
  float: left;
  text-decoration: none;
}

.btnside:hover {
  background: linear-gradient(90deg, #005c97 0%, #363795 100%);
  color: white;
  transform: scale(1);
  transition: ease-in-out 0.1s;
}

.btnside.active {
  background: linear-gradient(90deg, #005c97 0%, #363795 100%);
  color: white;
}

/* CSS SELECT BOX */
/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #ffffff;
  background-image: none;
  border: 0.2px solid rgb(202, 202, 202);
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 400px;
  /* margin-bottom: 10px; */
  height: 3.5em;
  line-height: 3;
  background: #ffffff;
  overflow: hidden;
  border-radius: 0.25em;
  border: 0.2px solid rgb(202, 202, 202);
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: rgb(83, 83, 83);
  cursor: pointer;
  border: 0.2px solid rgb(202, 202, 202);
  font-size: 1rem;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #ffffff;

  /* border: 0.2px solid rgb(202, 202, 202); */
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}

/* SCROLLBAR  TEXTURE*/

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.ant-card {
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
}

.ant-collapse-header {
  color: red;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: red;
  /* background-color: #e90f87; */
  font-size: 17px;
  font-weight: bold;
  color: rgb(111, 10, 10);
  /* border-radius: 50px; */
}

.ant-collapse-content > .ant-collapse-content-box {
  background-color: #fff;
  border-radius: 20px;
  /* color: #ddd; */
}

.swal-wide {
  width: 450px;
  color: black;
  height: 400px;
}

.container {
  margin-top: 15px;
}

.comment {
  display: block;
  transition: all 1s;
}

.commentClicked {
  min-height: 0px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px 10px;
}

textarea {
  width: 100%;
  border: none;
  background: #e8e8e8;
  padding: 5px 10px;
  height: 50%;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 2px solid #016ba8;
  transition: all 0.5s;
  margin-top: 15px;
}

button.primaryContained {
  background: #016ba8;
  color: #fff;
  padding: 10px 10px;
  border: none;
  margin-top: 0px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  transition: 1s all;
  font-size: 10px;
  border-radius: 5px;
}

button.primaryContained:hover {
  background: #9201a8;
}
.ant-list-items {
  cursor: pointer;
  transition: ease-in-out 3s;
  /* margin: 100px; */
  padding: 20px;
  margin: 10px;
}

.ant-list-item-meta-avatar {
  font-size: 18px;
}

.listname {
  color: #025bba;
  font-weight: 500;
}

.listdescription {
  font-size: 18px;
  color: #505050;
}

.descriptionView {
  font-size: 16px;
  text-align: justify;
}

.ant-list-item {
  padding: 10px !important;
}

.ant-list-items :hover {
  /* transform: scale(1.01); */
  /* transition: ease-in-out 3s; */
  background-color: rgb(240, 240, 240);
  /* padding: 2px; */
  border-radius: 10px;

  /* background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%); */
  /* color: crimson; */
}

.containerpic {
  position: relative;
  text-align: center;
  /* color: black; */
  max-height: 400px;
  max-width: 400px;
  margin-bottom: 0px;
  z-index: 1;
}

.centeredpic {
  position: absolute;
  top: 50%;
  left: 35%;
  max-height: 400px;

  /* transform: translate(-50%, -50%); */
  z-index: 2;
}

.ant-row .ant-form-item {
  color: crimson;
}
textarea {
  background-color: white;
  border: 0.3px solid #cfcfcf;
  border-radius: 4px;
}

textarea:focus {
  /* width: 100%; */
  outline-color: #1d9ef5;
  outline: 0.3px solid #1d9ef5;
  border-radius: 4px;
}

.majorcard::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.majorcard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgb(231, 231, 231);
  border-radius: 2px;
}

/* Handle */
.majorcard::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

/* Handle on hover */
.majorcard::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
